<template>
    <div>
        <div class="friend-link">
            <a-row type="flex" justify="center">
                <a-col :lg="13">
                    <p>
                        <span>友情链接：</span>
                        <router-link :to="'/type/' + item.id" v-for="item in typeList" :key="item.id">{{ item.type_name }}</router-link>
                    </p>
                </a-col>
            </a-row>
        </div>
        <!-- 底部开始吧 -->
        <div class="footer">
            <div class="center" v-if="system">
                <a-row type="flex" justify="center">
                    <a-col :lg="4" class="qrcode">
                        <div class="qrcode-div">
                            <img width="185" height="45" v-if="system" v-lazy="getUrl(system.system_logo)" alt="" />
                            <!-- <h5>微信二维码</h5> -->
                        </div>
                    </a-col>
                    <a-col :lg="9" class="nav">
                        <ul>
                            <li><router-link to="/">网站首页</router-link></li>
                            <li v-for="item in typeList" :key="item.id">
                                <router-link :to="'/type/' + item.id">{{ item.type_name }}</router-link>
                            </li>
                        </ul>
                        <a-row class="footer-bottom">
                            <a-col :lg="8"> 国内专线：{{ system.system_phone }} </a-col>
                            <a-col :lg="8">24小时热线：{{ system.system_phone }} </a-col>
                        </a-row>
                        <a-row class="footer-bottom">
                            <a-col :lg="8"> 微信：15355002335</a-col>
                            <a-col :lg="8">Email: {{ system.system_email }} </a-col>
                        </a-row>
                        <a-row class="footer-bottom" align="middle" style="height: 50px">
                            <a-col :lg="12" style="line-height: 48px"> 公司地址：{{ system.system_address }} </a-col>
                            <a-col :lg="12" class="phone"> <a-icon type="phone" /> {{ system.system_phone }} </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </div>
        </div>

        <footer v-if="system">
            {{ system.system_copyright }}
            <a href="https://beian.miit.gov.cn/">备案号： {{ system.system_icp }}</a>
        </footer>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'Footer',

    data() {
        return {};
    },
    computed: {
        ...mapState('system', ['system']),
        ...mapState('types', ['typeList']),
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
footer {
    height: 30px;
    background-color: #000;
    color: #fff;
    line-height: 30px;
    text-align: center;
}

.phone {
    display: flex;
    align-items: center;
    font-size: 24px;
}

.anticon-phone {
    font-size: 48px;
}

.footer {
    position: relative;
}

.center {
    height: 300px;
    background: url('@/assets/footer.jpg') no-repeat;
    background-size: 100%;
    position: relative;
    z-index: 11;

    .ant-row-flex {
        position: absolute;
        top: 80px;
        width: 100%;

        .qrcode {
            .qrcode-div {
                display: flex;
                flex-direction: column;
                align-items: center;

                h5 {
                    margin-top: 10px;
                    text-align: center;
                    color: #fff;
                    font-weight: 600;
                }
            }
        }

        .nav {
            ul {
                display: flex;
                margin-bottom: 20px;

                li {
                    font-size: 16px;
                    margin-right: 30px;

                    a {
                        color: #fff;
                    }
                }
            }

            .footer-bottom {
                font-size: 16px;
                color: #fff;
                margin-top: 10px;
            }
        }
    }
}

// .center::after {
//     content: '';
//     display: inline-block;
//     width: 0;
//     height: 0;
//     border-top: 80px solid #fff;
//     border-left: 1903px solid transparent;
//     position: absolute;
// }
.friend-link {
    background-color: RGB(241, 241, 241);
    height: 30px;

    p {
        line-height: 30px;

        span {
            color: RGB(189, 115, 126);
            font-weight: 600;
        }

        a {
            color: rgba(0, 0, 0, 0.67);
            margin-right: 20px;
        }

        a:first-child {
            margin-left: 20px;
        }
    }
}
</style>
