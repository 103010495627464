import request from '@/utils/request';

// 获取类型列表
function reqTypeList() {
    return request.post('/getTypeList');
}
// 上传图片组件
function reqUploadImage(params) {
    return request.post('/uploadImage', params);
}

// 上传表单
function reqMessageSubmit(params) {
    return request.post('/messageSubmit', params);
}

function reqTextareaImageUpload(params) {
    return request.post('/reqTextareaImageUpload', params);
}

function reqTest(params) {
    return request.post('/test', params);
}

//  获取上传视频七牛的token
function reqGetUploadVideoQiNiuToken(params) {
    return request.post('/getUploadToken', params);
}
// 获取用户是否唯一
function reqRegisterUserNameIsUnique(params) {
    return request.post('/reqRegisterUserNameIsUnique', params);
}
// 获取验证码
function reqRegisterCode(params) {
    return request.post('/send', params);
}
// 获取验证码
function reqRegister(params) {
    return request.post('/register', params);
}

export function reqGetArticleHome() {
    return request.post('/reqGetArticleHome');
}

function reqLogin(params) {
    return request.post('/login', params);
}

// 更新用户信息
function reqUpdateUserInfo(params) {
    return request.post('/reqUpdateUserInfo', params);
}
// 日志添加
export function reqAddLog(params) {
    return request.post('http://daily.szfcmedia.com/log/add', params);
}
export function reqIllegal(params) {
    return request.post('http://daily.szfcmedia.com/illegal/getIllegal', params);
}

// 申请公司入驻
export function reqAddCompany(params) {
    return request.post('/reqAddCompany', params);
}

// 搜索
export function reqSearch(params) {
    return request.post('/reqSearch', params);
}

// 获取系统的信息
export const reqGetSystem = () => request.post('/getSiteInfo');

// 获取轮播
export const reqGetSlide = () => request.post('/getSlide');

// 获取轮播
export const reqGetArticle = id => request.post('/getArticle', id);

// 获取类型列表
export const reqGetTypeListById = id => request.post('/reqGetTypeListById', id);
export const reqGetMessageById = id => request.post('/reqGetMessageById', id);
export const regGetAddressList = id => request.post('/regGetAddressList', id);

export const reqGetMyPublish = () => request.post('/reqGetMyPublish');
export const reqDelMyPublishMessage = id => request.post('/reqDelMyPublishMessage', id);
export const reqGetSiteInfo = () => request.post('/getSiteInfo');
export const reqGetRecommend = () => request.post('/reqGetRecommend');
export const reqGetMessageByType = i => request.post('/reqGetMessageByType', i);
export const reqUserInfo = () => request.post('/getUserInfoByToken');
export const reqLogout = () => request.post('/logout');
export { reqTypeList, reqUploadImage, reqMessageSubmit, reqGetUploadVideoQiNiuToken, reqTextareaImageUpload, reqRegisterUserNameIsUnique, reqRegisterCode, reqRegister, reqLogin, reqUpdateUserInfo };
