export default [
    {
        path: '/',
        redirect: 'home',
        meta: {
            isFooterHidden: true,
        },
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Index'),
    },
    {
        path: '/type/:id',
        name: 'type',
        component: () => import('@/views/type'),
    },
    {
        path: '/info/:id',
        name: 'info',
        component: () => import('@/views/info/info'),
    },
    {
        path: '/publish',
        name: 'publish',
        component: () => import('@/views/publish'),
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('@/views/company'),
    },
    {
        path: '/search',
        name: 'search',
        component: () => import('@/views/search'),
    },
    {
        path: '/companyIndex',
        name: 'companyIndex',
        component: () => import('@/views/company/company'),
    },
    {
        path: '/article/:id',
        name: 'article',
        component: () => import('@/views/article/index.vue'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/login.vue'),
        meta: {
            isFooterHidden: true,
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('@/views/login/register.vue'),
        meta: {
            isFooterHidden: true,
        },
    },
    {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my'),
        children: [
            {
                //欢迎页
                path: '',
                name: 'me',
                meta: {
                    title: '我的资料',
                    keepalive: true,
                },
                component: () => import('@/views/my/components/me.vue'),
            },
            {
                //欢迎页
                path: '/myPublish',
                name: 'myPublish',
                meta: {
                    title: '我的发布',
                    keepalive: true,
                },
                component: () => import('@/views/my/components/myPublish.vue'),
            },
            {
                //欢迎页
                path: '/mypay',
                name: 'mypay',
                meta: {
                    title: '充值会员',
                    keepalive: true,
                    isHome: false,
                },
                component: () => import('@/views/my/components/pay.vue'),
            },
        ],
    },
];
