<template>
    <div>
        <Header v-if="!$route.meta.isFooterHidden"></Header>
        <router-view />
        <Footer v-if="!$route.meta.isFooterHidden"></Footer>
    </div>
</template>
<script>
import Header from '@/components/Header/';
import Footer from '@/components/Footer';
import { mapActions } from 'vuex';

export default {
    name: 'app',
    components: {
        Header,
        Footer,
    },
    methods: {
        ...mapActions('types', ['getTypeList']),
    },
    mounted() {
        this.getTypeList();
    },
};
</script>
<style lang="less">
@width: 70%;
body {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
}
ul {
    padding: 0;
}
ul li {
    list-style: none;
}
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}
</style>
