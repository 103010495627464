import axios from 'axios';
import store from '@/store';
const request = axios.create({
    // baseURL: 'http://x253.www.pusite.icu/api',
    baseURL: 'http://x253.www.pusite.icu/api',
    // baseURL: 'http://4.qjjl.site/api',
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
});
request.interceptors.request.use(config => {
    config.headers['Token'] = store.state.user.token;
    return config;
});
request.interceptors.response.use(
    response => {
        return response.data;
    },
    err => {
        return Promise.reject(err);
    }
);
export default request;
