<template>
    <header>
        <nav>
            <a-row>
                <a-col type="flex" :span="12" :xs="{ span: 16 }">
                    <span>{{ system.system_name }}</span>
                </a-col>
                <a-col :span="12" :xs="{ span: 8 }">
                    <a-row type="flex" v-if="!userInfo">
                        <router-link to="/login">登录</router-link>
                        <a-divider type="vertical" />
                        <router-link to="/register">注册</router-link><a-divider type="vertical" />
                        <router-link to="/article/7">关于我们</router-link>
                    </a-row>

                    <a-row type="flex" v-else>
                        <a-dropdown>
                            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                                <a-avatar style="margin-right: 6px" v-if="userInfo.face" :src="getUrl(userInfo.face)"> </a-avatar>
                                <a-avatar v-else icon="user" style="margin-right: 6px"> </a-avatar>
                                {{ userInfo.username }}
                                <a-icon type="down" />
                            </a>
                            <a-menu slot="overlay">
                                <a-menu-item>
                                    <router-link to="/my">个人中心</router-link>
                                </a-menu-item>
                                <a-menu-item>
                                    <a href="javascript:;" @click="logout">退出登录</a>
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </a-row>
                </a-col>
            </a-row>
        </nav>
        <div class="logo_phone">
            <a-row style="height: 100%">
                <a-col :lg="4" :xs="24" class="img-div">
                    <a href="/" class="img-a">
                        <img v-if="system.system_logo" v-lazy="getUrl(system.system_logo)" alt="" />
                    </a>
                </a-col>
                <a-col :lg="{ span: 4, offset: 1 }" :xs="{ span: 24 }" class="desc">
                    <h5>专业食品供应发布平台</h5>
                    <p>精益求精，精选品质典范</p>
                </a-col>

                <a-col :lg="{ span: 5, offset: 10 }" :xs="{ span: 24 }" class="phone-div">
                    <div class="phone">
                        <span class="phone-title">平台客服热线：</span>
                        <Fonts type="icon-lianxiwomen"></Fonts>
                        <div class="phone-number">{{ system.system_phone }}</div>
                        <button class="btn">在线咨询</button>
                        <span class="d"></span>
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="nav">
            <a-row class="main">
                <a-col style="height: 100%" :lg="{ span: 18 }">
                    <ul>
                        <li>
                            <a href="/">网站首页</a>
                        </li>
                        <li v-for="item in typeList" :key="item.id">
                            <router-link :to="'/type/' + item.id">{{ item.type_name }}</router-link>
                        </li>

                        <li v-if="system?.system_url">
                            <a :href="system?.system_url">在线商城</a>
                        </li>
                        <!-- <li>
                            <router-link to="/publish">信息发布</router-link>
                        </li> -->
                        <!-- <li>
                            <router-link to="/company">公司入驻</router-link>
                        </li> -->
                        <!-- 
            <li>
              <a href="http://www.ailingjie.com/" target="_blank">在线商城</a>
            </li> -->
                        <!-- <li>
              <router-link to="/article/7">帮助文档</router-link>
            </li> -->
                    </ul>
                </a-col>
                <a-col :lg="{ span: 6 }" :xs="12" class="search-div">
                    <form m>
                        <input type="text" v-model="keyword" />
                        <button type="button" @click="search">
                            <a-icon type="search" />
                        </button>
                    </form>
                    <a-button @click="$router.push('/publish')" style="margin-left: 10px" type="primary" icon="edit">信息发布</a-button>
                </a-col>
            </a-row>
        </div>
    </header>
</template>

<script>
import { Icon } from 'ant-design-vue';
import { mapState, mapActions } from 'vuex';
import { reqIllegal } from '@/api';
const Fonts = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/c/font_3916546_zu9bwjzaqhk.js',
});
export default {
    name: 'Header',
    components: {
        Fonts,
    },
    watch: {
        keyword: {
            handler(val) {
                if (this.keywordList.indexOf(val) > -1) {
                    alert('出现屏蔽字，清空屏蔽字输入框内容');
                    this.keyword = '';
                }
            },
        },
    },
    computed: {
        ...mapState('types', ['typeList']),
        ...mapState('user', ['userInfo']),
    },
    data() {
        return {
            system: [],
            keyword: '',
            keywordList: [],
        };
    },

    async created() {
        // const data = await reqIllegal();
        // if (data.code == 1) {
        //   this.keywordList = data.data;
        // }
        this.getInfo();
    },

    methods: {
        ...mapActions('user', ['logOut']),
        ...mapActions('system', ['getSystem']),
        async getInfo() {
            this.system = await this.getSystem();
        },
        async logout() {
            const data = await this.logOut();
            if (data.code == 200) {
                this.$message.success(data.msg);
            } else {
                return this.$message.error(data.msg);
            }
        },
        search() {
            this.$router.push({
                path: '/search',
                query: { keyword: this.keyword },
            });
        },
    },
};
</script>

<style lang="less" scoped>
@width: 70%;

@media screen and (max-width: 768px) {
    ul {
        li {
            margin-right: 0;
        }
    }
}

.anticon-user {
    line-height: 35px !important;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .nav {
        height: 50px;
        background-color: RGB(46, 184, 184);

        .main {
            width: @width;
            margin: 0 auto;
            height: 100%;

            ul {
                display: flex;
                align-items: center;
                height: 100%;

                li {
                    a {
                        color: #fff;
                        font-size: 14px;
                    }
                }

                li:hover {
                    a {
                        color: #fcc072;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .nav {
        height: 50px;
        background-color: RGB(46, 184, 184);
        border-bottom: 1px solid;
        border-color: #eee;
        .main {
            width: @width;
            margin: 0 auto;
            height: 100%;

            ul {
                display: flex;
                align-items: center;
                height: 100%;

                li {
                    margin-right: 40px;

                    a {
                        color: #fff;
                        font-size: 14px;
                    }
                }

                li:hover {
                    a {
                        color: #fcc072;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

header {
    .search-div {
        height: 100%;
        display: flex;
        align-items: center;

        form {
            height: 30px;
            display: flex;
            width: 250px;
            background-color: #fff;
            border-radius: 20px;

            input {
                border: 0;
                outline: 0;
                padding-left: 10px;
                border-radius: 20px;
                width: 210px;
            }

            button {
                border: 0;
                outline: 0;
                background-color: #fff;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }

    .logo_phone {
        width: @width;
        margin: 0 auto;
        height: 135px;

        .ant-row {
            height: 100%;

            .ant-col {
                height: 100%;
            }
        }

        .desc {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: center;

            h5 {
                color: RGB(73, 191, 197);
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 5px;
            }

            p {
                color: #333;
            }
        }

        .img-div {
            display: flex;
            align-items: center;
            justify-content: center;

            .img-a {
                display: block;
                height: 45px;
                width: 100%;
                width: 185px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

        .phone-div {
            display: flex;
            align-items: center;

            .phone {
                width: 100%;
                height: 55px !important;
                border: 1px solid #eee;
                position: relative;
                z-index: -1;

                .d {
                    position: absolute;
                    width: 20px;
                    height: 20px;
                    background-color: #fff;
                    right: -15px;
                    top: 20px;
                }

                .btn {
                    color: #fff;
                    padding: 4px 25px;
                    background-color: RGB(60, 168, 196);
                    border: 0;
                    letter-spacing: 2px;
                    position: relative;
                    left: 28%;
                    top: -10px;
                    cursor: pointer;
                }

                .phone-title {
                    font-size: 14px;
                    font-weight: 700;
                    position: absolute;
                    z-index: 100;
                    top: -12px;
                    left: 30%;
                    padding: 0 3px;
                    background-color: #fff;
                }

                .phone-number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    color: RGB(252, 192, 114);
                    font-size: 24px;
                    font-weight: 700;
                }

                i {
                    font-size: 36px;
                    color: RGB(46, 184, 184);
                    position: absolute;
                    z-index: 10;
                    top: 5px;
                    left: -15px;
                    background-color: #fff;
                }
            }
        }
    }

    nav {
        height: 35px;
        background-color: RGB(241, 241, 241);

        .ant-row {
            width: @width;
            margin: 0 auto;
            height: 100%;

            .ant-col {
                height: 100%;

                span {
                    line-height: 35px;
                }
            }

            .ant-col:last-child {
                .ant-row-flex {
                    height: 100%;
                    justify-content: flex-end;
                    align-items: center;

                    a {
                        color: #222;
                        font-size: 14px;
                    }

                    a:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
</style>
